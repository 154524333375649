.ForecastResultsContainer {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.ForecastResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
}

.ForecastResultsPaper {
  width: 95%;
  max-width: 1000px;
  margin-top: 30px;
  padding-bottom: 10px;
}

.ForecastResultsHeader {
  font-weight: 700;
  font-size: 2em;
  margin-top: 12px;
  margin-left: 20px;
  margin-right: 20px;
}