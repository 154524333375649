.ForecastOptionsPaper {
  width: 95%;
  max-width: 1000px;
  margin-top: 30px;
  padding-bottom: 10px;
}

.ForecastOptionsSelector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}