.ForecastResultsPaper {
  width: 100%;
  max-width: 1600px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
  background-color: '#f4f4f4';
}

.ForecastResultsStack {
  padding-top: 6px;
}
