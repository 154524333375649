.WeatherConditionsContainer {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.WeatherForecastResultPaper {
  width: 95%;
  max-width: 1000px;
  margin-top: 30px;
  padding-bottom: 10px;
  background-color: #ddd;
}